import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Button, List, Input, Space, Divider } from 'antd';
import axios from "axios";

const { Search } = Input;


const Dioscorus = () => {
  const [data, setData] = useState<any>(null);
  const history = useHistory();

  useEffect(() => {
    axios.get("https://db.d-scribes.philhist.unibas.ch/api/showcase/dioscorus")
      .then(response => setData(response.data))
      .catch(error => console.log(error))
  }, []);

  const search_by_name = (value) => {
    axios.get(`https://db.d-scribes.philhist.unibas.ch/api/showcase/dioscorus/${value}`)
      .then(response => setData(response.data))
      .catch(error => console.log(error))
  }

  const showData = (data: any) => {
    return (
      <div style={{ width: '60%', margin: '0 auto' }}>
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item: any) => (
            <List.Item>
              <List.Item.Meta
                title={<a style={{ fontSize: 22 }} onClick={() => history.push(`/papyri/${item.id}`)}>{item.name}</a>}
              />
              <Button onClick={() => history.push(`/papyri/${item.id}`)}> Show </Button>
            </List.Item>
          )}
        />
      </div>
    );
  };

  return <div>
    <Search
      placeholder="input search text"
      allowClear
      enterButton="Search"
      size="large"
      onSearch={(value) => search_by_name(value)}
    />
    <Divider />
    <Space wrap style={{ display: 'flex', justifyContent: 'center' }}>
          <h4>Get <a style={{color: '#2192FF'}} onClick={() => history.push(`/listWriters/1`)}> papyrus </a> by writer</h4>
        </Space>
    <Divider />
    {/* /1 is the collction_id for dioscorus */}
    <Space wrap style={{ display: 'flex', justifyContent: 'center' }}>
      <Button onClick={() => history.push(`/papyrus/subscription/1`)}>Subscription</Button>
      <Button onClick={() => history.push(`/papyrus/paelographicAssignation/1`)}>Paelographic assignation</Button>
      <Button onClick={() => history.push(`/papyrus/securelyAttributedText/1`)}>Securely attributed text</Button>
    </Space>
    <Divider />
    {/* Render login form if there is no data else show the data */}
    { !data ? <p>Loading...</p>: showData(data) }
  </div>
}

export default Dioscorus;
