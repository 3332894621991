import React, { Component } from "react";
import mirador from "mirador";
import "./mirador.css"


class Mirador extends Component {

    componentDidMount() {
        mirador.viewer({
            id: "mirador",
            annotations: {
              htmlSanitizationRuleSet: 'iiif',
              filteredMotivations: ['oa:commenting', 'oa:tagging', 'sc:painting', 'commenting', 'tagging'],
            },
            window: {
              defaultSideBarPanel: "annotations",
              sideBarOpenByDefault: false,
              highlightAllAnnotations: true,
            },
            windows: [
              {
                manifestId: this.props.config.url,
              }
            ]
          });          
    }

    componentDidUpdate() {
      mirador.viewer({
          id: "mirador",
          annotations: {
            htmlSanitizationRuleSet: 'iiif',
            filteredMotivations: ['oa:commenting', 'oa:tagging', 'sc:painting', 'commenting', 'tagging'],
          },
          window: {
            defaultSideBarPanel: "annotations",
            sideBarOpenByDefault: false,
            highlightAllAnnotations: true,
          },
          windows: [
            {
              manifestId: this.props.config.url,
            }
          ]
        });          
  }

    render() {
        const { config } = this.props;
        return <div id={config.id} url={config.url}/>;
    }
}

export default Mirador;
