import * as React from "react";
import {
    BrowserRouter as Router,
    Route,
    Link
} from "react-router-dom";

import Layout from 'antd/lib/layout';
import Menu from 'antd/lib/menu';

import Home from './components/Home';
import Image from "./components/Image";
import Papyrus from "./components/Papyrus"
import Dioscorus from "./components/Dioscorus";
import Papas from "./components/Papas";
import logo from './assets/logo2_Diosc4.png';
import useWindowSize from "./useWindowSize";

import Index from "./components/PalEx/Index.jsx";
import IndexBodmer from "./components/PalEx2/Index.jsx";
import IndexCoco from "./components/PalEx-cocoTest/Index";

import homer32 from './components/coco.json';
import bodmer from './components/PalEx2/cocoBodmer.json';
import cocoTest from './components/PalEx-cocoTest/PalExHomerCompTestingReadCoco.json';
import cocoHomer2 from './components/PalEx-cocoTest/coco-homer2.json';
import filteredHomer2 from './components/PalEx-cocoTest/FilteredCoco-homer2.json';
import {useEffect, useState} from "react";
import CanvasCreator from "./components/PalEx/CanvasCreator";

import ImageArea from "./components/ImageArea";
import ListWriters from "./components/ListWriters";
import ImageByWriter from "./components/ImageByWriter";
import ImageWriter from "./components/ImageWriter";
import NotFound from "./components/PalEx/NotFound";
import IndexTM from "./components/PalEx/IndexTM";



const {Header, Content, Footer} = Layout;

/**
 * BrowserRouter is the top-level component and this looks for Route
 * components beneath it to determine all the different page paths.
 * During rendering, if the path in a Route component matches the current
 * path, the component will be rendered, and if not, null will be rendered.
 */

const Routes = () => {
    const onClickMenu = ({item, key}) => setKey(key);
    const [key, setKey] = React.useState("1");
    let default_URL_prefix = false;

    const [curURL, setCurURL] = React.useState(null);
    const [width, setWidth] = React.useState(null);
    const [height, setHeight] = React.useState(null);
    const [annotations, setAnnotations] = React.useState([]);
    const [categories, setCategories] = React.useState([]);
    const [file, setFile] = useState(null);
    const [currentFile, setCurrentFile] = useState('');
    const [currentFileName, setCurrentFileName] = useState('');

    // Update variables
    const changeUrlPrefix = (newUrlPrefix) => {
        default_URL_prefix = newUrlPrefix
    }

    const OnUrlChange = (newUrl) => {
        setCurURL(newUrl);
    }
    
    const changeWidth = (newWidth) => {
        setWidth(newWidth);
    }
    
    const changeHeight = (newHeight) => {
        setHeight(newHeight);
    }
    
    const changeAnnotations = (newAnnotations) => {
        setAnnotations(newAnnotations);
    }
    
    const changeCategories = (newCategories) => {
        setCategories(newCategories);
    }
    
    // Get variables
    const getURL = () => {
        if(default_URL_prefix){
            return 'https://app.d-scribes.philhist.unibas.ch/' + curURL;
        }else{
            return curURL;
        }
    }
    
    const getWidth = () => {
        return width;
    }
    
    const getHeight = () => {
        return height;
    }
    
    const getAnnotations = () => {
        return annotations;
    }
    
    const getCategories = () => {
        return categories;
    }

    // JSON parse the input file
    useEffect(() => {
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setCurrentFile(JSON.parse(e.target.result));
                // CanvasCreator.setKey(prevKey => prevKey + 1);
            }
            reader.readAsText(file);
        }
    }, [file]); // Add currentFile as a dependency

    // Fetch the filename from the URL and load the file locally
    useEffect(() => {
        const fetchFile = async (id) => {
            try {
                const response = await fetch(`../../${id}.json`);
                console.log(response);
                const fileData = await response.json();
                setCurrentFile(fileData);
            } catch (error) {
                console.error('Error fetching file:', error);
            }
        };

        const { pathname } = window.location;
        const parts = pathname.split('/');
        const filename = parts[parts.indexOf('palex') + 1]; // Access the filename after "palex"
        if (filename) {
            fetchFile(filename);
        }
    }, []); // Run once on component mount

    const handleFileChange = (e) => {
        if (e.target.files) {
            setCurrentFile('');
            setFile(e.target.files[0]);
            const fileName = e.target.files[0].name;
            const fileNameWithoutExtension = fileName.replace('.json', '');
            setCurrentFileName(fileNameWithoutExtension);
        }
    };

    const size = useWindowSize()
    return (
        <Router>
            <Layout style={{minHeight: '100vh'}}>
                <Header style={{backgroundColor: 'white', height: 70}}>
                    <Link to="/">
                        <img
                            alt=""
                            src={logo}
                            style={{
                                position: 'absolute',
                                top: '0.2%',
                                left: '4%',
                                width: 150,
                                height: 68,
                            }}
                        />
                    </Link>
                    <Menu
                        onClick={onClickMenu}
                        mode="horizontal"
                        style={{position: 'absolute', right: '5%', height: 65}}
                    >
                        <Menu.Item key="1" defaultChecked>
                            <Link to="/">Home</Link>
                        </Menu.Item>
                        <Menu.Item key="2">
                            <Link to="/Dioscorus">Dioscorus</Link>
                        </Menu.Item>
                        <Menu.Item key="3">
                            <Link to="/Papas">Papas</Link>
                        </Menu.Item>
                        {/* Show logout button only if the user is logged in */}
                        {
                            localStorage.getItem("auth") == "true" ?
                                <Menu.Item key="4">
                                    <Link to="/Logout">Logout</Link>
                                </Menu.Item> : null
                        }
                    </Menu>
                </Header>
                <Content
                    style={{
                        fontFamily: 'Verdana',
                        padding: '20px 50px',
                        marginTop: '2.5%',
                        marginBottom: '2.5%',
                        overflowY: key === '2' || key === '3' || size.height <= 800 ? 'scroll' : 'hidden',
                    }}
                >
                    <div style={{overflow: 'scroll', height: '100%'}}>
                        <Route component={NotFound} path='/notfound'/>
                        <Route component={Home} exact={true} path='/'/>
                        <Route component={Dioscorus} path='/Dioscorus'/>
                        <Route
                            exact
                            path="/viewer"
                            render={(props) => (
                                <div>
                                    <h1 style={{ textAlign: "center", marginTop: "20%" }}>
                                        Welcome to PalEx
                                    </h1>
                                    <div>
                                        <p>
                                            PalEx is a web-based platform for visualizing annotated images. It is
                                            designed to be user-friendly and easy to use. It is also designed to be
                                            flexible and customizable, allowing users to filter and analyze annotations.
                                        </p>
                                        <p>
                                            To get started, simply upload a COCO JSON file with annotations.
                                        </p>
                                        <p>
                                            <a href="/palex">Go to PalEx</a>
                                        </p>
                                    </div>
                                </div>
                            )}
                        />
                        <Route
                            exact={true}
                            path="/palex"
                            render={(props) => {
                                console.log('palex');
                                return (
                                    <div>
                                        <h1 style={{textAlign: 'center'}}>PalEx</h1>
                                        <div>
                                            <input id="file" type="file" onChange={handleFileChange}/>
                                        </div>
                                        <br/>
                                        {currentFile && <Index
                                            {...props} // Pass down route props
                                            fileNameURLParam = {currentFileName}
                                            file={currentFile}
                                            getURL={getURL}
                                            getHeight={getHeight}
                                            getWidth={getWidth}
                                            getAnnotations={getAnnotations}
                                            getCategories={getCategories}
                                            OnUrlChange={OnUrlChange}
                                            changeWidth={changeWidth}
                                            changeHeight={changeHeight}
                                            changeAnnotations={changeAnnotations}
                                            changeCategories={changeCategories}
                                        />}
                                    </div>
                                )}}
                        />
                        <Route
                            exact={true}
                            path="/palex/:filePathURLParam"
                            render={(props) => {
                                console.log('palex/file');
                                const { filePathURLParam } = props.match.params; // Access the id parameter from the URL
                                const { imageIdURLParam } = props.match.params; // Access the id parameter from the URL
                                return (
                                    <div>
                                        <h1 style={{textAlign: 'center'}}>PalEx</h1>
                                        <div>
                                            <input id="file" type="file" onChange={handleFileChange}/>
                                        </div>
                                        <br/>
                                        {currentFile && <Index
                                            {...props} // Pass down route props
                                            fileNameURLParam={filePathURLParam}
                                            fileNameURLParam1={filePathURLParam}
                                            imageIdURLParam={imageIdURLParam}
                                            file={currentFile}
                                            getURL={getURL}
                                            getHeight={getHeight}
                                            getWidth={getWidth}
                                            getAnnotations={getAnnotations}
                                            getCategories={getCategories}
                                            OnUrlChange={OnUrlChange}
                                            changeWidth={changeWidth}
                                            changeHeight={changeHeight}
                                            changeAnnotations={changeAnnotations}
                                            changeCategories={changeCategories}
                                        />}
                                    </div>
                                )}}
                        />
                        <Route
                            exact={true}
                            path="/palex/:filePathURLParam/:imageIdURLParam"
                            render={(props) => {
                                const { filePathURLParam } = props.match.params; // Access the id parameter from the URL
                                const { imageIdURLParam } = props.match.params; // Access the id parameter from the URL
                                return (
                                    <div>
                                        <h1 style={{textAlign: 'center'}}>PalEx</h1>
                                        <div>
                                            <input id="file" type="file" onChange={handleFileChange}/>
                                        </div>
                                        <br/>
                                        {currentFile && <Index
                                            {...props} // Pass down route props
                                            history={props.history} // Pass history object as a prop
                                            fileNameURLParam={currentFileName}
                                            fileNameURLParam1={filePathURLParam}
                                            imageIdURLParam={imageIdURLParam}
                                            file={currentFile}
                                            getURL={getURL}
                                            getHeight={getHeight}
                                            getWidth={getWidth}
                                            getAnnotations={getAnnotations}
                                            getCategories={getCategories}
                                            OnUrlChange={OnUrlChange}
                                            changeWidth={changeWidth}
                                            changeHeight={changeHeight}
                                            changeAnnotations={changeAnnotations}
                                            changeCategories={changeCategories}
                                        />}
                                    </div>
                                )}}
                        />
                        <Route path="/palexTM">
                            <IndexTM
                                file={homer32}
                                getURL={getURL}
                                getHeight={getHeight}
                                getWidth={getWidth}
                                getAnnotations={getAnnotations}
                                getCategories={getCategories}
                                OnUrlChange={OnUrlChange}
                                changeWidth={changeWidth}
                                changeHeight={changeHeight}
                                changeAnnotations={changeAnnotations}
                                changeCategories={changeCategories}
                            />
                        </Route>
                        <Route path="/ViewerBodmer">
                            <IndexBodmer
                                file={bodmer}
                                getURL={getURL}
                                getHeight={getHeight}
                                getWidth={getWidth}
                                getAnnotations={getAnnotations}
                                getCategories={getCategories}
                                OnUrlChange={OnUrlChange}
                                changeWidth={changeWidth}
                                changeHeight={changeHeight}
                                changeAnnotations={changeAnnotations}
                                changeCategories={changeCategories}
                                changeUrlPrefix={changeUrlPrefix}
                            />
                        </Route>
                        <Route path="/ViewerTest">
                            <IndexCoco
                                file={filteredHomer2}
                                getURL={getURL}
                                getHeight={getHeight}
                                getWidth={getWidth}
                                getAnnotations={getAnnotations}
                                getCategories={getCategories}
                                OnUrlChange={OnUrlChange}
                                changeWidth={changeWidth}
                                changeHeight={changeHeight}
                                changeAnnotations={changeAnnotations}
                                changeCategories={changeCategories}
                            />
                        </Route>
                        <Route component={Papas} path='/Papas'/>
                        <Route
                            render={() => {
                                localStorage.setItem("auth", "false");
                                window.location.href = "/Login";
                            }}
                            path='/Logout'
                        />
                        <Route component={Papyrus} path="/papyri/:id" />
                        <Route component={Image} path="/image/:id"/>
                        <Route component={ImageArea} path="/papyrus/:type/:collection_id"/>
                        <Route component={ListWriters} path="/listWriters/:collection_id"/>
                        <Route component={ImageByWriter} path="/imageByWrite/:id"/>
                        <Route component={ImageWriter} path="/imageWriter/:writer_id/:image_id"/>
                    </div>
                </Content>
                <Footer style={{textAlign: 'center', background: 'white'}}>
                    D-Scribes ©{new Date().getFullYear()} All rights reserved
                </Footer>
            </Layout>
        </Router>
    );
};

export default Routes;
