import React, { useState, useEffect } from "react";
import { Button, Form, Input, List, Space, Divider } from 'antd';
import { useHistory } from 'react-router-dom';
import axios from "axios";

const { Search } = Input;

const Papas = () => {
  const history = useHistory();
  const [data, setData] = useState<any>(null);
  const [authFailed, setAuthFailed] = useState(false);
  const auth = localStorage.getItem("auth");
  const email = localStorage.getItem("email");
  const password = localStorage.getItem("password");

  useEffect(() => {
    if (auth === "true") {
      handleSubmit(email, password);
    }
  }, []);

  const handleSubmit = async (email, password) => {
    try {
      const response = await axios.get(`https://db.d-scribes.philhist.unibas.ch/api/showcase/papas?email=${email}&password=${password}`);
      if(response.data.status !== 401){
        setData(response.data);
        // Save email and password and auth state if loggin is successful
        localStorage.setItem("email", email);
        localStorage.setItem("password", password);
        localStorage.setItem("auth", "true");
        setAuthFailed(false);
      } else {
        // Set auth to false if authentication fails
        setAuthFailed(true);
        localStorage.setItem("auth", "false");
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  const search_by_name = (value) => {
    axios.get(`https://db.d-scribes.philhist.unibas.ch/api/showcase/papas/${value}?email=${email}&password=${password}`)
      .then(response => setData(response.data))
      .catch(error => console.log(error))
  }
  
  const showData = (data: any) => {
    if (!data) {
      return <p>Loading...</p>
    } 

    return (
      <div style={{ width: '60%', margin: '0 auto' }}>
        <Search
          placeholder="input search text"
          allowClear
          enterButton="Search"
          size="large"
          onSearch={(value) => search_by_name(value)}
        />
        <Divider />
        <Space wrap style={{ display: 'flex', justifyContent: 'center' }}>
          <h4>Get <a style={{color: '#2192FF'}} onClick={() => history.push(`/listWriters/2`)}> papyrus </a> by writer</h4>
        </Space>
        <Divider />
        {/* /2 is the collction_id for dioscorus */}
        <Space wrap style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={() => history.push(`papyrus/subscription/2`)}>Subscription</Button>
          <Button onClick={() => history.push(`papyrus/paelographicAssignation/2`)}>Paelographic assignation</Button>
          <Button onClick={() => history.push(`papyrus/securelyAttributedText/2`)}>Securely attributed text</Button>
        </Space>
        <Divider />
        
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item: any) => (
            <List.Item>
              <List.Item.Meta
                title={<a style={{ fontSize: 22 }} onClick={() => history.push(`/papyri/${item.id}`)}>{item.name}</a>}
              />
              <Button onClick={() => history.push(`/papyri/${item.id}`)}> Show </Button>
            </List.Item>
          )}
        />
      </div>
    );
  };

  const successfulLogin = (values: any) => {
    handleSubmit(values.email, values.password);
  };
  
  const unSuccessfulLogin = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const loginForm = () => {
    return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
        <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={successfulLogin}
            onFinishFailed={unSuccessfulLogin}
            autoComplete="off"
            >
            <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, message: 'Please input your email!' }]}
            >
                <Input />
            </Form.Item>
        
            <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
                <Input.Password />
            </Form.Item>
        
            {/* <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
                <Checkbox>Remember me</Checkbox>
            </Form.Item> */}
            { authFailed ? <p style={{ color: 'red', display: 'flex', 
                            justifyContent: 'center', alignItems: 'center', fontSize: 15 }}> 
                            Please check your username/password. </p> : null }
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                Log in
                </Button>
            </Form.Item>
        </Form>
    </div>
  };
  const not_authenticated = localStorage.getItem("auth") === null || localStorage.getItem("auth") == "false";
  return (
    <div>
        {/* Render login when authentication is null or false otherwise show data */}
        { not_authenticated ? loginForm() : showData(data)}
    </div>
  );

}

export default Papas;
