import React from 'react';

function NotFound() {
    return (
        <div>
            <h2 style={{textAlign: "center"}}>404 - Page Not Found</h2>
            <p>The page you are looking for does not exist.</p>
        </div>
    );
}

export default NotFound;
