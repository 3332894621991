import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom"
import Mirador from "./Mirador"
import { Card, Table, Modal } from 'antd';
import axios from "axios";

const { Column } = Table;


const ImageWriter = () => {
    const [imageName, setImageSet] = useState([]);
    const [imageAreas, setImageAreas] = useState([]);
    const [clickedImageArea, setClickedImageArea] = useState<any>();
    const [writerData, setWriterData] = useState({});
    const [clickedWriter, setClickedWriter] = useState<any>();

    const showWriter = (name, patronymic, title, trismegistos, rufini) => {
        const data = {
            'name': name,
            'patronymic': patronymic,
            'title': title,
            'trismegistos': trismegistos,
            'rufini': rufini
        }
        setWriterData(data)
        setClickedWriter(true);
    };

    const getData = (name, image_areas) => {
        setImageSet(name);
        setImageAreas(image_areas);
    };

    useEffect(() => {
        axios.get(`https://db.d-scribes.philhist.unibas.ch/api/showcase/image/${params["image_id"]}`)
          .then(response => getData(response.data[0]['name'], response.data[0]['image_areas']))
          .catch(error => console.log(error))
    }, []);

    const params = useParams();

    return (
        <>
            <h3 style={{textAlign: 'center'}}> {imageName} </h3>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', height: '100vh' }}>
                <Card
                    hoverable
                    style={{ width: '80vw', height: '80vh' }}
                    cover={<Mirador config={{ id: "mirador" , url: `https://db.d-scribes.philhist.unibas.ch/api/showcase/writer/manifest/${params["writer_id"]}/${params["image_id"]}`}} />}
                />
            </div>

            {/* <Table dataSource={imageAreas} pagination={false}>
                <Column title="Name" dataIndex='name' key='name' render={(name) => (
                       <a style={{color: '#2192FF'}} onClick={() => setClickedImageArea(imageAreas.find((obj) => obj['name'] === name))}> {name} </a>
                    )}
                />
                <Column title="Contains" dataIndex="contains" key="contains" />
                <Column title="Fragment" dataIndex="fragment_id" key="fragment_id" />
                <Column
                    title="Writer"
                    dataIndex="papyrus_writers"
                    key="papyrus_writers"
                    render={(papyrus_writers) => (
                        <>
                            {papyrus_writers.map((index) => (
                              <a style={{color: '#2192FF'}} onClick={() => showWriter(index.writer.name, 
                                index.writer.patronymic, index.writer.title, index.writer.trismegistos_person, index.writer.rufini)}> 
                                {index.writer.name} 
                              </a>
                            ))}
                        </>
                    )}
                />
            </Table>
            <br /> <br />
            {
                clickedImageArea ? <Table dataSource={[clickedImageArea]} pagination={false}>
                <Column
                        title="Descriptor"
                        dataIndex="papyrus_writers"
                        key="papyrus_writers"
                        render={(papyrus_writers) => (
                            <>
                                {papyrus_writers.map((index) => (
                                  <a> {index.descriptor} </a>
                                ))}
                            </>
                        )}
                />
                <Column
                        title="Trismegistos"
                        dataIndex="papyrus_writers"
                        key="papyrus_writers"
                        render={(papyrus_writers) => (
                            <>
                                {papyrus_writers.map((index) => (
                                  <a> {index.papyrus.trismegistos} </a>
                                ))}
                            </>
                        )}
                />
                <Column
                        title="Language"
                        dataIndex="papyrus_writers"
                        key="papyrus_writers"
                        render={(papyrus_writers) => (
                            <>
                                {papyrus_writers.map((index) => (
                                  <a> {index.papyrus.language} </a>
                                ))}
                            </>
                        )}
                />
                <Column
                        title="Content"
                        dataIndex="papyrus_writers"
                        key="papyrus_writers"
                        render={(papyrus_writers) => (
                            <>
                                {papyrus_writers.map((index) => (
                                  <a> {index.papyrus.content} </a>
                                ))}
                            </>
                        )}
                />
                <Column
                        title="Provenance"
                        dataIndex="papyrus_writers"
                        key="papyrus_writers"
                        render={(papyrus_writers) => (
                            <>
                                {papyrus_writers.map((index) => (
                                  <a> {index.papyrus.provenance} </a>
                                ))}
                            </>
                        )}
                />
                <Column
                        title="Hand"
                        dataIndex="papyrus_writers"
                        key="papyrus_writers"
                        render={(papyrus_writers) => (
                            <>
                                {papyrus_writers.map((index) => (
                                  <a> {index.hand} </a>
                                ))}
                            </>
                        )}
                />
                <Column
                        title="Paleographic Assignation"
                        dataIndex="papyrus_writers"
                        key="papyrus_writers"
                        render={(papyrus_writers) => (
                            <>
                                {papyrus_writers.map((index) => (
                                  <a> {index.paleographic_assignation} </a>
                                ))}
                            </>
                        )}
                />
                </Table> : null
            }
            <br /> <br />
            {
                <Table dataSource={[writerData]} pagination={false}>
                    <Column title="Writer Name" dataIndex="name" key="name" />
                    <Column title="Patronymic" dataIndex="patronymic" key="patronymic" />
                    <Column title="Title" dataIndex="title" key="title" />
                    <Column title="Trismegistos" dataIndex="trismegistos" key="trismegistos" />
                    <Column title="Rufini" dataIndex="rufini" key="rufini" />
                </Table> 
            } */}
        </>
    )
}

export default ImageWriter;
