import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom"
import { Button, List, Avatar, Space, Divider } from 'antd';

import axios from "axios";


const ListWriters = () => {
    const params = useParams();
    const [data, setData] = useState<any>(null);
    const history = useHistory();
  
    useEffect(() => {
      axios.get(`https://db.d-scribes.philhist.unibas.ch/api/showcase/writers/${params["collection_id"]}`)
        .then(response => setData(response.data))
        .catch(error => console.log(error))
    }, [params["type"]]);
  
    const showData = (data: any) => {
      return (
        <div style={{ width: '60%', margin: '0 auto' }}>
          <List
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item: any) => (
              <List.Item>
                <List.Item.Meta
                  title={<a style={{ fontSize: 22 }} onClick={() => history.push(`/imageByWrite/${item.id}`)}>{item.name}</a>}
                />
                <Button onClick={() => history.push(`/imageByWrite/${item.id}`)}> Show </Button>
              </List.Item>
            )}
          />
        </div>
      );
    };
  
    return <div>
      {/* <Space wrap style={{ display: 'flex', justifyContent: 'center' }}>
        <h4>Click <a style={{color: '#2192FF'}} onClick={() => history.push(`/Dioscorus`)}> here </a> to go back to dioscorus</h4>
      </Space>
      <Space wrap style={{ display: 'flex', justifyContent: 'center' }}>
        <Button type={params["type"] == 'subscription' ? 'primary': 'default'} 
            onClick={() => history.push(`/imagearea/subscription`)}>Subscription</Button>
        <Button type={params["type"] == 'paelographicAssignation' ? 'primary': 'default'} 
            onClick={() => history.push(`/imagearea/paelographicAssignation`)}>Paelographic assignation</Button>
        <Button type={params["type"] == 'securelyAttributedText' ? 'primary': 'default'} 
            onClick={() => history.push(`/imagearea/securelyAttributedText`)}>Securely attributed text</Button>
      </Space>
      <Divider /> */}
      {/* Render login form if there is no data else show the data */}
      { !data ? <p>Loading...</p>: showData(data) }
    </div>
}

export default ListWriters;
