import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom"
import { Button, List, Avatar, Space, Divider, Table } from 'antd';
import axios from "axios";

const { Column } = Table;

const ImageByWriter = () => {
  const params = useParams();
  const [data, setData] = useState<any>(null);
  const [writerName, setWriterName] = useState(null);
  const [writerId, setWriterId] = useState(null);
  const [limit, setLimit] = useState<number>(15);
  const history = useHistory();

  const getData = (data, image_areas, writer_name) => {
    setData(data);
    setWriterName(image_areas);
    setWriterId(writer_name);
  };
  useEffect(() => {
    axios.get(`https://db.d-scribes.philhist.unibas.ch/api/showcase/writer/${params["id"]}`)
      .then(response => getData(response.data, response.data[0]['image_areas'][0]["papyrus_writers"][0]["writer"]["name"], response.data[0]['image_areas'][0]["papyrus_writers"][0]["writer"]["id"]))
      .catch(error => console.log(error))
  }, []);

  const showData = (data: any) => {
    const limitedData = data.slice(0, limit);
    return (
      <div style={{ width: '60%', margin: '0 auto' }}>
        <List
          itemLayout="horizontal"
          dataSource={limitedData}
          renderItem={(item: any) => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar style={{ height: 100, width: 100 }} src={`https://db.d-scribes.philhist.unibas.ch/iiif/${item.name}/0,0,0,0/full/0/default.jpg`} />}
                // Redirect to writerImage where /writerId/imageId are matching the image we want to fetch
                title={<a style={{ fontSize: 22 }} onClick={() => history.push(`/imageWriter/${writerId}/${item.id}`)}>{item.name}</a>}
              />
              <Button onClick={() => history.push(`/image/${item.id}`)}> Show </Button>
            </List.Item>
          )}
        />
        {limit < data.length &&
          <p style={{ cursor: 'pointer', color: 'blue', marginTop: 15, fontSize: 22 }} onClick={() => setLimit(limit + 15)}> More </p>
        }
      </div>
    );
  };
  
  return <div>
    <Space wrap style={{ display: 'flex', justifyContent: 'center' }}>
    <h4>You are currently viewing the work of: {writerName}</h4>
    </Space>
    <Divider />
    <Space wrap style={{ display: 'flex', justifyContent: 'center' }}>
      <h4>Get back to <a style={{color: '#2192FF'}} onClick={() => history.push(`/listWriters/1`)}> dioscorus </a> writers</h4>
    </Space>
    {localStorage.getItem("auth") == "true" ? <Space wrap style={{ display: 'flex', justifyContent: 'center' }}>
      <h4>Or <a style={{color: '#2192FF'}} onClick={() => history.push(`/listWriters/2`)}> papas </a> writers</h4>
    </Space>: null}
    <Divider />
    {/* <Space wrap style={{ display: 'flex', justifyContent: 'center' }}>
      <Button disabled onClick={() => history.push(`/imagearea/subscription`)}>Subscription</Button>
      <Button disabled onClick={() => history.push(`/imagearea/paelographicAssignation`)}>Paelographic assignation</Button>
      <Button disabled onClick={() => history.push(`/imagearea/securelyAttributedText`)}>Securely attributed text</Button>
    </Space>
    <Divider /> */}
    {/* Render login form if there is no data else show the data */}
    { !data ? <p>Loading...</p>: showData(data) }
  </div>
}

export default ImageByWriter;
