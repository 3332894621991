import * as React from "react"
// import { Typography, Divider } from 'antd';
import Layout from "antd/lib/layout";
// import Img from "antd/lib/image"
import Typography from "antd/lib/typography";
import useWindowSize from "../useWindowSize";
// import Space from "antd/lib/space";
// import Mirador from "./Mirador"
// import { instance } from "../axios-instance"
// import { logo } from "../logo";

const { Sider, Content } = Layout;


const { Title, Paragraph, Link } = Typography

// Nella home non dobbiamo più mostrare mirador ma una descrizione come qui
// https://spotlight.vatlib.it/greek-paleography
const Home = () => {
    const size = useWindowSize()
    return (
        <Layout style={{
            padding: '0 175px 0 0',
            height: '90%',
            width: '100%',
            backgroundImage: "url('./images/background.png')",
            backgroundRepeat: 'round',
        }}>
            {size.height >= 800 &&
                <Sider style={{ background: 'trasparent', paddingTop: '0%', opacity: 0 }} />
            }
            <Content style={{
                opacity: 0.9, backgroundColor: '#ffffff', padding: '0px 35px 0px 35px'
            }}>
                <Title style={{fontFamily:'Swift Neue LT W01'}}>Introduction</Title>
                <Paragraph>
                   <Title style={{fontFamily:'Swift Neue LT W01'}} level={3}>{`Welcome to D-Scribes!`}</Title>
                </Paragraph>
                <Paragraph>
                   <Title style={{fontFamily:'Verdana'}} level={5}> {`Welcome to the website of the SNSF Ambizione project "Reuniting fragments, identifying scribes and characterizing scripts: the Digital paleography of Greek and Coptic papyri" (September 2018 – August 2022)
`}</Title>
                </Paragraph>
                <Paragraph style={{fontFamily:'Verdana'}}>
                {`Greek and Coptic papyri preserved in the sands of Egypt are one of our main sources for Classical and early Christian literature. They are also crucial for the field of Ancient History of the Eastern Mediterranean world, as the texts found written in them span from the Hellenistic period to the Middle Ages. The papyri are often incomplete, however, broken accidentally during the course of time or, in more recent periods, intentionally cut to be sold in pieces in the antiquities market. It is precisely because of the fragmentary nature of these irreplaceable texts that palaeography, the study of ancient handwritings, is of great importance to papyrologists. First, palaeography helps to identify the specific handwriting of one individual over several fragments. Secondly, it provides dating arguments in the absence of other criteria, since Greek and Coptic scripts have developed consistently over time.`}
                </Paragraph>
                <Paragraph style={{fontFamily:'Verdana'}}>
                {`The present research project aims to lay the foundation for an online interface through which papyrologists would be able to look for similar – or identical – handwritings to a given papyrus and for typical samples of writing for a given period. To reach this ambitious goal, this project will first focus on three distinct and complementary case studies: `}
                </Paragraph>
                <Paragraph style={{fontFamily:'Verdana'}}>
                    <li> the papyri which contain Homer’s Iliad, written in Ancient Greek;</li>
                    <li> the richest archive of the Byzantine period which is called the archive of Dioscorus of Aphrodito written in Greek and Coptic (from the 6th c. A.D.); </li>
                    <li> and the archive of Papas, written in Coptic, whose papyri were found broken in a jar and thus represent a real jigsaw puzzle for papyrologists. </li>
                </Paragraph>
               
            </Content>
        </Layout >

        // <div >
        //     <Mirador config={{ id: "mirador" }} />
        // </div>
    )
}
export default Home