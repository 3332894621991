import React, { useState, useEffect } from "react";
import { useParams, useHistory } from 'react-router-dom';
import { Button, List, Avatar} from 'antd';
import axios from "axios";

const Papyrus = () => {
  const params = useParams();     
  const [data, setData] = useState<any>(null);
  const history = useHistory();

  useEffect(() => {
    axios.get(`https://db.d-scribes.philhist.unibas.ch/api/showcase/papyri/${params["id"]}`)
      .then(response => setData(response.data))
      .catch(error => console.log(error))
  }, []);

  const showData = (data: any) => {
    return (
      <div style={{ width: '60%', margin: '0 auto' }}>
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item: any) => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar style={{ height: 100, width: 100 }} src={`https://db.d-scribes.philhist.unibas.ch/iiif/${item.name}/0,0,0,0/full/0/default.jpg`} />}
                title={<a style={{ fontSize: 22 }} onClick={() => history.push(`/image/${item.id}`)}>{item.name}</a>}
              />
              <Button onClick={() => history.push(`/image/${item.id}`)}> Show </Button>
            </List.Item>
          )}
        />
      </div>
    );
  };

  return <div>
    {/* Render login form if there is no data else show the data */}
    { !data ? <p>Loading...</p>: showData(data) }
  </div>
}

export default Papyrus;
